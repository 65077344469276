import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function PersonalData() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Согласие на обработку персональных данных`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Персональные данные'
      />

      <section className='flex flex-col items-center '>
        <h1 className='text-2xl uppercase mb-4 font-bold'>
          Согласие на обработку персональных данных
        </h1>
        <div className='card w100p mb20'>
          <div className='card-body'>
            <p className='mb-2'>
              Настоящим Я, далее –{' '}
              <strong>«Субъект Персональных Данных»</strong>, во исполнение
              требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О
              персональных данных» (с изменениями и дополнениями) свободно,
              своей волей и в своем интересе даю свое согласие образовательной
              организации <strong>ЧОУ ДПО &quot;ПРОФФ&quot;</strong>
            </p>
            <p className='mb-2'>
              (далее – &quot;Образовательная организация&quot; , адрес: 628383,
              Ханты-Мансийский Автономный округ - Югра АО, Пыть-Ях г, 6
              Пионерный мкр, дом № 30) на обработку своих персональных данных,
              указанных при регистрации путем заполнения веб-формы на сайте
              Образовательной организации (http://proff86.ru далее - &quot;Сайт
              образовательной организации&quot;), направляемой (заполненной) с
              использованием Сайта образовательной организации.
            </p>
            <p className='mb-2'>
              Под персональными данными Я понимаю любую информацию, относящуюся
              ко мне как к Субъекту Персональных Данных, в том числе мои
              фамилию, имя, отчество, адрес, образование, профессию, контактные
              данные (телефон, электронная почта, почтовый адрес), фотографии,
              иную другую информацию, позволяющую меня идентифицировать. Под
              обработкой персональных данных я понимаю сбор, систематизацию,
              накопление, уточнение, обновление, изменение, использование,
              распространение, передачу, в том числе трансграничную,
              обезличивание, блокирование, уничтожение, бессрочное хранение), и
              любые другие действия (операции) с персональными данными.
            </p>
            <p className='mb-2'>
              Обработка персональных данных Субъекта Персональных Данных
              осуществляется исключительно в целях регистрации Субъекта
              Персональных Данных в базе данных &quot;Образовательной
              организации&quot; с последующим направлением Субъекту Персональных
              Данных почтовых сообщений и смс-уведомлений, от Образовательной
              организации, его аффилированных лиц и/или субподрядчиков,
              информационных и новостных рассылок, приглашений на мероприятия
              Образовательной организации и другой информации
              рекламно-новостного содержания, а также с целью подтверждения
              личности Субъекта Персональных Данных при посещении мероприятий
              Образовательной организации.
            </p>
            <p className='mb-2'>
              Датой выдачи согласия на обработку персональных данных Субъекта
              Персональных Данных является дата отправки регистрационной
              веб-формы с Сайта образовательной организации. Обработка
              персональных данных Субъекта Персональных Данных может
              осуществляться с помощью средств автоматизации и/или без
              использования средств автоматизации в соответствии с действующим
              законодательством РФ и внутренними положениями Образовательной
              организации.
            </p>
            <p className='mb-2'>
              Образовательная организация принимает необходимые правовые,
              организационные и технические меры или обеспечивает их принятие
              для защиты персональных данных от неправомерного или случайного
              доступа к ним, уничтожения, изменения, блокирования, копирования,
              предоставления третьим лицам, распространения персональных данных,
              а также от иных неправомерных действий в отношении персональных
              данных, а также принимает на себя обязательство сохранения
              конфиденциальности персональных данных Субъекта Персональных
              Данных. Образовательная организация вправе привлекать для
              обработки персональных данных Субъекта Персональных Данных с
              убподрядчиков, а также вправе передавать персональные данные для
              обработки своим аффилированным лицам, обеспечивая при этом
              принятие такими субподрядчиками и аффилированными лицами
              соответствующих обязательств в части конфиденциальности
              персональных данных.
            </p>
            <p className=' font-bold mb-2'>Я ознакомлен(а), что:</p>
            <ul>
              <li>
                настоящее согласие на обработку моих персональных данных,
                указанных при регистрации на Сайте образовательной организации,
                направляемых (заполненных) с использованием Сайта
                образовательной организации, действует в течение 20 (двадцати)
                лет с момента регистрации на Сайте образовательной организации;
              </li>
              <li>
                согласие может быть отозвано мною на основании письменного
                заявления в произвольной форме, поданной в адрес Образовательной
                организации;
              </li>
              <li>
                предоставление персональных данных третьих лиц без их согласия
                влечет ответственность в соответствии с действующим
                законодательством Российской Федерации.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default PersonalData;
